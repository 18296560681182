<script>
    import { onMount, beforeUpdate } from 'svelte';
    import GanttHelper from './GanttHelper.js';
    import OrderGanttBarUnit from './OrderGanttBarUnit.svelte'
    import { SelectRectangle } from "../../utils/selectRectangle";
    import { roundToNearestTime, getTimeStringSec } from '../../utils/time.js';
    import { translate } from '../../i18n/i18next.js';

    export let GanttStore
    export let ID;
    export let Height;
    export let OriginY;
    export let Data;
    export let StartDate;
    export let EndDate;
    export let StyleIndex;
    export let AdjustStamp;
    export let IsOperationBarCoveredOnOrderBar; // Whether is operation bar covered on order bar 
    export let IsOperation; // whether is operation bar unfolded in a separate line

    export let ganttChartType = GanttStore.GanttChartTypes.OrderGantt;

    $: simplifiedProductionArr = null;
    $: simple = (ganttChartType == GanttStore.GanttChartTypes.SimpleOrderGantt || ganttChartType == GanttStore.GanttChartTypes.ExpectedActualCmp); // whether it is simple gantt chart bar;
    $: eac = (ganttChartType == GanttStore.GanttChartTypes.ExpectedActualCmp);

    let rect = document.getElementById("gantt_chartarea_chartcontent").getBoundingClientRect();

    GanttStore.LeftTableWidth.subscribe((_) => {
      rect = document.getElementById("gantt_chartarea_chartcontent").getBoundingClientRect();
    });

    $: adjustedHeight = Math.min(Math.max(Height / 2, 1), 5);
    $: adjustedY = OriginY + Height - adjustedHeight;
    $: adjustedLET = CalculateStampX(Data._let) - adjustedHeight * rect.height / 2 / rect.width;
    $: adjustedEST = CalculateStampX(Data._est) - adjustedHeight * rect.height / 2 / rect.width;


    $: {
      if (simple) { // For simple resource gantt, only reserve the earliest and latest date
        const allDates = [...Data._productionArr];

        const earliestDate = allDates.reduce((earliest, current) => 
            current < earliest ? current : earliest, allDates[0]);

        const latestDate = allDates.reduce((latest, current) => 
            current > latest ? current : latest, allDates[0]);

        simplifiedProductionArr = [roundToNearestTime(earliestDate, 1, true), roundToNearestTime(latestDate, 1)];
      } 
    }
  
    let Selected = false;
    let optimizationBarString = 10;
    let optimizationBarBorder = 30;
    let optimizationShapeSimplify = 50;
    let displayedRowCount = 5;
  
    const CalculateStampX = (stamp) => Math.max(0, Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime())));
  
    let hbsrtunsub = () => {};
    let bssrtunsub = () => {};
    let hbbrtunsub = () => {};
    let drcunsub = () => {};
  
    onMount( () => {
      rect = document.getElementById("gantt_chartarea_chartcontent").getBoundingClientRect();
    });

    beforeUpdate(() => {
      hbsrtunsub();
      bssrtunsub();
      hbbrtunsub();
      drcunsub();
  
      Selected = SelectRectangle.IsSelectedId(ID.toString());
      hbsrtunsub = GanttStore.OPTIMIZATION_HideBarStringRowThreshold.subscribe((hbsrt) => optimizationBarString = hbsrt);
      bssrtunsub = GanttStore.OPTIMIZATION_BarSimplifyShapeRowThreshold.subscribe((bssrt) => optimizationShapeSimplify = bssrt);
      bssrtunsub = GanttStore.OPTIMIZATION_HideBarBorderRowThreshold.subscribe((hbbrt) => optimizationBarBorder = hbbrt);
      hbbrtunsub = GanttStore.DisplayedRowCount.subscribe((drc) => displayedRowCount = drc);
    });
  
  </script>
  
  <g
    class={"selectable resourceganttbar" + (Selected ? " selected" : "")}
    id={ID}
  >
     {#if !simple && Data?._setupArr?.length >= 2}
      <OrderGanttBarUnit
        Timestamps={Data._setupArr}
        Color="#999999"
        StartYMain={OriginY+Height*0.325}
        HeightYMain={Height*0.35}
        StartDate={StartDate}
        EndDate={EndDate}
        HiddenBarBorders={true}
        SimplifiedShape={displayedRowCount >= optimizationShapeSimplify}
        AdjustStamp={AdjustStamp}
        Transparent={displayedRowCount >= optimizationBarBorder}
      />
    {/if}
  
    {#if !simple && Data?._teardownArr?.length >= 2}
      <OrderGanttBarUnit
        Timestamps={Data._teardownArr}
        Color="#999999"
        StartYMain={OriginY+Height*0.325}
        HeightYMain={Height*0.35}
        StartDate={StartDate}
        EndDate={EndDate}
        HiddenBarBorders={true}
        SimplifiedShape={displayedRowCount >= optimizationShapeSimplify}
        AdjustStamp={AdjustStamp}
        Transparent={displayedRowCount >= optimizationBarBorder}
      />
    {/if}
  
    {#if Data._productionArr.length >= 2}
    <!-- Delay happens, do not use simplified productionArr -->
      {#if Data._let && Data._productionArr[Data._productionArr.length - 1] > Data._let} 
        <!-- There exist a part of bar before the LET -->
        {#if Data._productionArr[0] < Data._let}
          <OrderGanttBarUnit
            Timestamps={[Data._productionArr[0], Data._let]}
            Color={simple? "#7dc4ff" : Data._barColors[StyleIndex]}
            StartYMain={!eac ? OriginY+Height*0.125 : OriginY+Height*0.375}
            HeightYMain={!eac ? Height*0.75 : Height*0.25}
            StartDate={StartDate}
            EndDate={EndDate}
            HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
            SimplifiedShape={true}
            AdjustStamp={AdjustStamp}
            Transparent={false}
            Highlight={Data._isRefOrder}
          />
        {/if}
        <!-- Note the case that all of the bar is later than the LET -->
        <OrderGanttBarUnit
          Timestamps={[Data._let >= Data._productionArr[0] ? Data._let : Data._productionArr[0], Data._productionArr[Data._productionArr.length - 1]]}
          Color="#ff7575"
          StartYMain={!eac ? OriginY+Height*0.125 : OriginY+Height*0.375}
          HeightYMain={!eac ? Height*0.75 : Height*0.25}
          StartDate={StartDate}
          EndDate={EndDate}
          HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
          SimplifiedShape={true}
          AdjustStamp={AdjustStamp}
          Transparent={false}
          Highlight={Data._isRefOrder}
        />
      {:else}
        {#if IsOperationBarCoveredOnOrderBar} 
          <OrderGanttBarUnit
            Timestamps={Data._productionArr}
            Color={simple? "#7dc4ff" : Data._barColors[StyleIndex]}
            StartYMain={OriginY+Height*0.2}
            HeightYMain={Height*0.6}
            StartDate={StartDate}
            EndDate={EndDate}
            HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
            SimplifiedShape={true}
            AdjustStamp={AdjustStamp}
            Transparent={IsOperationBarCoveredOnOrderBar && !IsOperation}
          />
        {:else}
        <!-- If the end date is in the same day as LET, do not fill in one day -->
        <!-- Do not use simplified arr for eac -->
          <OrderGanttBarUnit
            Timestamps={simple && !eac && 
            (!(Data._let && Data._productionArr[Data._productionArr.length - 1].toDateString() == Data._let.toDateString())) ? 
            simplifiedProductionArr : 
            Data._productionArr}
            Color={simple? "#7dc4ff" : Data._barColors[StyleIndex]}
            StartYMain={!eac ? OriginY+Height*0.125 : OriginY+Height*0.375}
            HeightYMain={!eac ? Height*0.75 : Height*0.25}
            StartDate={StartDate}
            EndDate={EndDate}
            HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
            SimplifiedShape={true}
            AdjustStamp={AdjustStamp}
            Transparent={false}
            Highlight={Data._isRefOrder}
          />
        {/if}
      {/if}
    {/if}
  
    {#if eac && Data._storedStartTime &&  Data._storedEndTime}
    <!-- Do not use simplified productionArr -->
      {#if Data._let && Data._storedEndTime > Data._let} 
        <!-- There exist a part of bar before the LET -->
        {#if Data._storedStartTime < Data._let}
          <OrderGanttBarUnit
            Timestamps={[Data._storedStartTime, Data._let]}
            Color={"#4fe89e"}
            StartYMain={OriginY+Height*0.1}
            HeightYMain={Height*0.25}
            StartDate={StartDate}
            EndDate={EndDate}
            HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
            SimplifiedShape={true}
            AdjustStamp={AdjustStamp}
            Transparent={false}
          />
        {/if}
        <!-- Note the case that all of the bar is later than the LET -->
        <OrderGanttBarUnit
          Timestamps={[Data._let >= Data._storedStartTime ? Data._let : Data._storedStartTime, Data._storedEndTime]}
          Color={"#4fe89e"}
          StartYMain={OriginY+Height*0.1}
          HeightYMain={Height*0.25}
          StartDate={StartDate}
          EndDate={EndDate}
          HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
          SimplifiedShape={true}
          AdjustStamp={AdjustStamp}
          Transparent={false}
        />
      {:else}
        <OrderGanttBarUnit
          Timestamps={[Data._storedStartTime, Data._storedEndTime]}
          Color={"#4fe89e"}
          StartYMain={OriginY+Height*0.1}
          HeightYMain={Height*0.25}
          StartDate={StartDate}
          EndDate={EndDate}
          HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
          SimplifiedShape={true}
          AdjustStamp={AdjustStamp}
          Transparent={false}
        />
      {/if}
    {/if}

    {#if eac && Data._resultStartTime &&  Data._resultEndTime}
      <!-- Do not use simplified productionArr -->
      {#if Data._let && Data._resultEndTime > Data._let} 
        <!-- There exist a part of bar before the LET -->
        {#if Data._resultStartTime < Data._let}
          <OrderGanttBarUnit
            Timestamps={[Data._resultStartTime, Data._let]}
            Color={"#ffb21c"}
            StartYMain={OriginY+Height*0.65}
            HeightYMain={Height*0.25}
            StartDate={StartDate}
            EndDate={EndDate}
            HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
            SimplifiedShape={true}
            AdjustStamp={AdjustStamp}
            Transparent={false}
          />
        {/if}
        <!-- Note the case that all of the bar is later than the LET -->
        <OrderGanttBarUnit
          Timestamps={[Data._let >= Data._resultStartTime ? Data._let : Data._resultStartTime, Data._resultEndTime]}
          Color={"#ffb21c"}
          StartYMain={OriginY+Height*0.65}
          HeightYMain={Height*0.25}
          StartDate={StartDate}
          EndDate={EndDate}
          HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
          SimplifiedShape={true}
          AdjustStamp={AdjustStamp}
          Transparent={false}
        />
      {:else}
        <OrderGanttBarUnit
          Timestamps={[Data._resultStartTime, Data._resultEndTime]}
          Color={"#ffb21c"}
          StartYMain={OriginY+Height*0.65}
          HeightYMain={Height*0.25}
          StartDate={StartDate}
          EndDate={EndDate}
          HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
          SimplifiedShape={true}
          AdjustStamp={AdjustStamp}
          Transparent={false}
        />
      {/if}
    {/if}

    <title>
      {Data._hoverTooltip[StyleIndex]}
    </title>
  
    {#if ganttChartType == GanttStore.GanttChartTypes.OrderGantt && (!IsOperationBarCoveredOnOrderBar || IsOperation) && displayedRowCount < 1.5 * optimizationBarString && Data._productionArr.length >= 1}
      <!-- Make text at the right side -->
      <text
        fill={(Data._late) ? "red" : "black"}
        font-size="{Math.min(20 / displayedRowCount, 2)}vh"
        x="{CalculateStampX(Data._productionArr[Data._productionArr.length - 1])}%"
        y="{ OriginY+Height*0.4}%"
        text-anchor="start"
        dominant-baseline="hanging"
        pointer-events="none"
      >
        {#if Data._taskText[StyleIndex]}
          {#each Data._taskText[StyleIndex].split("\n") as text, idx}
            <tspan
              x="{CalculateStampX(Data._productionArr[Data._productionArr.length - 1])}%"
              dy="{(idx==0) ? 0 : (10 / displayedRowCount)}vh"
            >
              {text}
            </tspan>
          {/each}
        {/if}
      </text>
    {/if}
  
  </g>

  {#if Data._let && CalculateStampX(Data._let) != 0 && CalculateStampX(Data._let) != 100}
    <image
      x="{adjustedLET}%"
      y="{adjustedY}%"
      height="{adjustedHeight}%"
      xlink:href={"/assets/triangle_red.svg"}
      style="cursor:pointer"
    >
      <title>
        {translate("asprova.terms.due")}: {getTimeStringSec(Data._let)}
      </title>
    </image>
  {/if}

  {#if Data._let && CalculateStampX(Data._est) != 0 && CalculateStampX(Data._est) != 100}
    <image
      x="{adjustedEST}%"
      y="{adjustedY}%"
      height="{adjustedHeight}%"
      xlink:href={"/assets/triangle_blue.svg"}
      style="cursor:pointer"
    >
      <title>
        {translate("asprova.terms.est")}: {getTimeStringSec(Data._est)}
      </title>
    </image>
  {/if}

  <style>
    g.resourceganttbar {
      outline: solid 4px hsla(0, 0%, 0%, 0);
    }
  
    /* g.resourceganttbar.selected {
      outline-color: yellow!important;
    } */
  </style>
  