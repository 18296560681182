/**
 * @type {import("svelte").Writable}
 */

import { derived, get, writable } from "svelte/store";
import GanttHelper from "../GanttHelper.js";
import { I18N_GetString } from "../../../utils/i18n.js";
import {
  getResGanttConfiguration,
  saveResGanttConfiguration
} from "../../../utils/configurationStorage/views/ResGanttConfigurationStorage.js";
import {
  getOrderGanttConfiguration,
  saveOrderGanttConfiguration
} from "../../../utils/configurationStorage/views/OrderGanttConfigurationStorage.js";

/**
 * @typedef {{
 *   GanttChartTypes: string[],
 *   GanttChartType: Writable<string>,
 *   BasisTime: Writable<Date>,
 *   StartDate: Writable<Date>,
 *   EndDate: Writable<Date>,
 *   DisplayedStartDate: Writable<Date>,
 *   DisplayedEndDate: Writable<Date>,
 *   CurrentDisplayedStartDate: Writable<Date>,
 *   CurrentDisplayedEndDate: Writable<Date>,
 *   ValidStartDate: Writable<Date>,
 *   ValidEndDate: Writable<Date>,
 *   RowName: Writable<"Resource" | "Order">,
 *   ProjectName: Writable<string>,
 *   ProjectId: Writable<string>,
 *   UserId: Writable<string>,
 *   Comment: Writable<string>,
 *   ExtraColumns: Writable<Array>,
 *   StyleObjects: Writable<Array>,
 *   CurrentStyle: Writable<object>,
 *   StylesDict: Writable<object>,
 *   StyleRowUnit: Writable<string>,
 *   CurrentStyleInstance: Writable<object>,
 *   RowsAllOwnedByStyle: Writable<Array>,
 *   RowsAllFiltered: Writable<Array>,
 *   RowsCountTotal: Writable<number>,
 *   RowsDisplayedStart: Writable<number>,
 *   RowsCurrentlyDisplayed: Writable<Array>,
 *   RowsCached: Writable<object>,
 *   RowsUnfoldedForOrderGantt: Writable<Set>,
 *   ElementLocations: Writable<object>,
 *   InternalLowerSplits: Writable<Array>,
 *   ShiftsList: Writable<object>,
 *   Tasks: Writable<Array>,
 *   TasksUI: Writable<object>,
 *   ReadyToRender: Writable<boolean>,
 *   SelectedObject: Writable<null>,
 *   SelectedId: Writable<null>,
 *   RowDownloadUrl: Writable<string>,
 *   TransactionsUrl: Writable<string>,
 *   Transactions: Writable<null>,
 *   TransactionRegisteringFunction: Writable<function>,
 *   LastUploadTimestamp: Writable<undefined>,
 *   LastTransactionDownloadTimestamp: Writable<undefined>,
 *   TimelineItems: Writable<Array>,
 *   TimelineHighlight: Writable<null>,  // Selected block index
 *   DisplayedRowCount: Writable<number>,
 *   MaxRowsWhereTextIsRendered: Writable<number>,
 *   StartDayDisplayTime: Writable<string>,
 *   EndDayDisplayTime: Writable<string>,
 *   StartDayDisplayTimePercent: Writable<string>,
 *   EndDayDisplayTimePercent: Writable<string>,
 *   RowSearchPattern: Writable<string>,
 *   DisplayedSpanInSeconds: Writable<number>,
 *   PreventMistakes: Writable<bool>,
 *   OPTIMIZATION_HideBarStringRowThreshold: Writable<number>,
 *   OPTIMIZATION_HideBarBorderRowThreshold: Writable<number>,
 *   OPTIMIZATION_HideShiftsRowThreshold: Writable<number>,
 *   OPTIMIZATION_HideShiftsColumnThreshold: Writable<number>,  // 1Month
 *   OPTIMIZATION_BarSimplifyShapeRowThreshold: Writable<number>,  // Always use simplified shape for new design
 * }} GanttStore
 */


const GANTT_STORE_PROPERTIES = {
  "GanttChartTypes": () => ({
    "ResGantt": "Resource Gantt Chart",
    "SimpleOrderGantt": "Simple Order Gantt Chart",
    "OrderGantt": "Order Gantt Chart",
    "SimpleResGantt": "Simple Resource Gantt Chart",
    "ExpectedActualCmp": "Expected and Actual Results Comparison"
  }),
  "GanttChartType": () => "",
  // Time chart parameters
  "BasisTime": () => new Date(),
  "StartDate": () => new Date(),
  "EndDate": () => new Date(),
  "DisplayedStartDate": () => new Date(),
  "DisplayedEndDate": () => new Date(),
  "CurrentDisplayedStartDate": () => new Date(),
  "CurrentDisplayedEndDate": () => new Date(),
  "ValidStartDate": () => new Date(),
  "ValidEndDate": () => new Date(),
  "RowName": () => "Resource",
  "ProjectName": () => "",
  "ProjectId": () => "",
  "UserId": () => "",
  "Comment": () => "",
  "ExtraColumns": () => [],
  "TransactionsUrl": () => "",
  // style parameters
  "StyleObjects": () => [],
  "CurrentStyle": () => {},
  "StylesDict": () => ({}),
  "StyleRowUnit": () => "Resource",
  "CurrentStyleInstance": () => ({}),
  // New Row Calculation Methods
  "RowsAllOwnedByStyle": () => [],
  "RowsAllFiltered": () => [],
  "RowsCountTotal": () => 0,
  "RowsDisplayedStart": () => 0,
  "RowsCurrentlyDisplayed": () => [],
  "RowsCached": () => ({}),
  "RowsUnfoldedForOrderGantt": () => new Set(),
  // Locations of each revealed element towards its row
  "ElementLocations": () => ({}),
  // Chart data internal values
  "InternalLowerSplits": () => [],
  "ShiftsList": () => ({}),
  "Tasks": () => [],
  "TasksUI": () => ({}),
  "ReadyToRender": () => false,
  "SelectedObject": () => null,
  "SelectedId": () => null,
  "RowDownloadUrl": () => "",
  // Transaction data
  "Transactions": () => null,
  "TransactionRegisteringFunction": () => () => {},
  "LastUploadTimestamp": () => undefined,
  "LastTransactionDownloadTimestamp": () => undefined,

  // Timeline
  "TimelineItems": () => [],
  "TimelineHighlight": () => null,  // Selected block index

  // Display settings
  "DisplayedRowCount": () => 5,
  "MaxRowsWhereTextIsRendered": () => 10,
  "StartDayDisplayTime": () => "00:00",
  "EndDayDisplayTime": () => "23:59",
  "StartDayDisplayTimePercent": () => "0%",
  "EndDayDisplayTimePercent": () => "100%",
  "RowSearchPattern": () => "",
  "DisplayedSpanInSeconds": () => 604800,
  "PreventMistakes": () => true,

  // Optimizations (Display settings sub-tab?)
  "OPTIMIZATION_HideBarStringRowThreshold": () => 20,
  "OPTIMIZATION_HideBarBorderRowThreshold": () => 30,
  "OPTIMIZATION_HideShiftsRowThreshold": () => 50,
  "OPTIMIZATION_HideShiftsColumnThreshold": () => 604800 * 4,  // 1Month
  "OPTIMIZATION_BarSimplifyShapeRowThreshold": () => 0,  // Always use simplified shape for new design
}

/**
 * Initializes a new GanttStore with the provided initial values.
 * @param {Partial<GanttStore>} [initialValues]
 * @returns GanttStore
 */
export function initGanttStore(initialValues) {
  // initializing the store
  const GanttChartTypes = {
    "ResGantt": "Resource Gantt Chart",
    "SimpleOrderGantt": "Simple Order Gantt Chart",
    "OrderGantt": "Order Gantt Chart",
    "SimpleResGantt": "Simple Resource Gantt Chart",
    "ExpectedActualCmp": "Expected and Actual Results Comparison",
    "CustomizedResGantt": "Resource Gantt Chart (Customized)"
  };

  const GanttChartType = writable(GanttChartTypes.ResGantt);

  // TimeChart parameters
  const BasisTime = writable(initialValues?.BasisTime ?? new Date());
  const StartDate = writable(new Date());
  const EndDate = writable(new Date());
  const DisplayedStartDate = writable(new Date());
  const DisplayedEndDate = writable(new Date());
  const CurrentDisplayedStartDate = writable(initialValues?.CurrentDisplayedStartDate ?? new Date());
  const ValidStartDate = writable(new Date());
  const ValidEndDate = writable(new Date());
  const ProjectName = writable("");
  const ProjectId = writable("");
  const UserId = writable("");
  const Comment = writable("");
  const ExtraColumns = writable([]);
  const TransactionsUrl = writable("");

  // Style parameters
  const StyleObjects = writable([]);
  const CurrentStyle = writable({});
  const StylesDict = writable({});


  const RowsDisplayedStart = writable(0);
  const RowsCached = writable({});
  const RowsUnfoldedForOrderGantt = writable(new Set()); // What rows are clicked and should be unfolded to show operations

  // Locations of each revealed element towards its row
  const ElementLocations = writable({});

  // Chart data internal values
  const InternalLowerSplits = writable([]);
  const ShiftsList = writable({});
  const Tasks = writable([]);
  const TasksUI = writable({});
  const ReadyToRerender = writable(false);
  const SelectedObject = writable(null);
  const SelectedId = writable(null);
  const RowDownloadUrl = writable("");

  // Transaction data
  const Transactions = writable(null);
  const TransactionRegisteringFunction = writable(() => {});
  const LastUploadTimestamp = writable(undefined);
  const LastTransactionDownloadTimestamp = writable(undefined);

  // Timeline
  const TimelineItems = writable([]);
  const TimelineHighlight = writable(null); // Selected block index

  // Display settings
  const DisplayedRowCount = writable(5);
  const MaxRowsWhereTextIsRendered = writable(10);
  const StartDayDisplayTime = writable("00:00");
  const EndDayDisplayTime = writable("23:59");
  const RowSearchPattern = writable("");
  RowSearchPattern.subscribe(() => RowsDisplayedStart.set(0))
  const DisplayedSpanInSeconds = writable(604800); // 1Week
  const LeftTableWidth = writable(200);
  const PreventMistakes = writable(true);

  // Optimizations (Display settings sub-tab?)
  const OPTIMIZATION_HideBarStringRowThreshold = writable(20);
  const OPTIMIZATION_HideBarBorderRowThreshold = writable(30);
  const OPTIMIZATION_HideShiftsRowThreshold = writable(50);
  const OPTIMIZATION_HideShiftsColumnThreshold = writable(604800 * 4); //1Month
  const OPTIMIZATION_BarSimplifyShapeRowThreshold = writable(0); // Always use simplified shape for new design

  // derivations
  const CurrentDisplayedEndDate = derived([DisplayedSpanInSeconds, CurrentDisplayedStartDate], ([$DisplayedSpanInSeconds, $CurrentDisplayedStartDate]) => {
    return new Date($CurrentDisplayedStartDate.getTime() + $DisplayedSpanInSeconds * 1000)
  })
  const RowName = derived(GanttChartType, $GanttChartType => {
    return $GanttChartType === GanttChartTypes.ResGantt || $GanttChartType === GanttChartTypes.SimpleResGantt || $GanttChartType === GanttChartTypes.CustomizedResGantt ? "資源": "オーダ"
  })
  const RowUnit = derived(GanttChartType, $GanttChartType => {
    return $GanttChartType === GanttChartTypes.ResGantt || $GanttChartType === GanttChartTypes.SimpleResGantt || $GanttChartType === GanttChartTypes.CustomizedResGantt ? "Resource": "Order"
  })
  const CurrentStyleInstance = derived([CurrentStyle, StylesDict], ([$CurrentStyle, $StylesDict]) => {
    if ($CurrentStyle.StyleName && $StylesDict[$CurrentStyle.StyleName] == null) {
      console.error(`Unable to get style ${$CurrentStyle.StyleName} from ${JSON.stringify($StylesDict)}`);
    }
    return $StylesDict[$CurrentStyle.StyleName];
  });
  // When the selected style is updated, reset the row index to 0.
  CurrentStyleInstance.subscribe(() => RowsDisplayedStart.set(0))
  // New Row Calculation Methods
  const RowsAllOwnedByStyle = derived([CurrentStyleInstance, RowUnit], ([$CurrentStyleInstance, $RowUnit]) => {
    if ($CurrentStyleInstance !== null && $CurrentStyleInstance !== undefined && $CurrentStyleInstance[$RowUnit]) {
      return $CurrentStyleInstance[$RowUnit].map((value, index) => {
        const {
          RowHeadColor,
          ResourceLeftText,
          ResourceGroup,
          ResourceName,
          Order_ItemCode,
          Order_ItemName,
          Order_Lateness,
          Order_OperationCode,
          Order_Seiban,
          Order_LET,
          ObjectID,
          OrderLeftText,
          ShowLabel
        } = $CurrentStyleInstance;
        let lateness = (Order_Lateness || [])[index] || "";
        if (lateness) {
          lateness = Math.ceil(Math.max(lateness / 3600 / 24, 0));
        }
        return {
          Row: (ObjectID || [])[index]?.toString() || value,
          RowType: $RowUnit,
          Color: RowHeadColor[index],
          Alias: (ResourceLeftText || OrderLeftText || [])[index] || value,
          ResourceGroup: (ResourceGroup || [])[index] || "",
          ResourceName: (ResourceName || [])[index] || "",
          Order_ItemCode: (Order_ItemCode || [])[index] || "",
          Order_ItemName: (Order_ItemName || [])[index] || "",
          Order_Lateness: lateness,
          Order_OperationCode: (Order_OperationCode || [])[index] || [],
          Order_Seiban:  (Order_Seiban || [])[index] || [],
          Order_LET : (Order_LET || [])[index] || [],
          ShowLabel : (ShowLabel || [])[index] || true
        };
      })
    }
    return []
  })
  const RowsAllFiltered = derived([RowSearchPattern, RowsAllOwnedByStyle], ([$RowSearchPattern, $RowsAllOwnedByStyle]) => {
    return GanttHelper._gantHelper_filterArray($RowsAllOwnedByStyle, $RowSearchPattern)
  })
  const RowsCountTotal = derived(RowsAllFiltered, $RowsAllFiltered => {
    return $RowsAllFiltered.length
  })
  const RowsCountTotalIncludingUnfoldedOperations = writable(0);
  const StartDayDisplayTimePercent = derived(StartDayDisplayTime, values => {
    return GanttHelper._gantHelper_hourToPercentageOfTheDay(values)
  })
  const EndDayDisplayTimePercent = derived(StartDayDisplayTime, values => {
    return GanttHelper._gantHelper_hourToPercentageOfTheDay(values)
  })
  // Displayed rows are updated then :
  /*
  ** 1: Filter method is changed
  ** 2: Displayed row count is changed
  ** 3: Starting row is changed
  ** 4. Pressing unfold button (only for OrderGantt)
  */
  const extractAndPad = (array, start, count) => Array.from({ length: count }, (_, index) => array[start + index] || null);

  function extraAndPadWithOperationUnfolded(RowsAllFiltered, RowsDisplayedStart, DisplayedRowCount, RowsUnfoldedForOrderGantt) {
    let rows = RowsAllFiltered;
    let newRows = [];
    let realIndex = 0;
    rows.forEach((r, idx) => {
      r.RealIndex = realIndex;
      newRows.push(r);
      realIndex++;
      let i = 0;
      if (r != null && r.Order_OperationCode.length === 0) {
        console.log(r);
        console.log(I18N_GetString("UploadAgain"));
        // window.alert(I18N_GetString("UploadAgain"));
      }
      while (r != null && i < r.Order_OperationCode.length && RowsUnfoldedForOrderGantt.has(idx)) {
        let newR = JSON.parse(JSON.stringify(r)); // deep copy
        newR.IsOperation = true;
        newR.Alias = r.Order_OperationCode[i]; // Let the alias be the operation code
        newR.RealRowIndex = realIndex;
        newRows.push(newR);
        i++;
      }
    });
    RowsCountTotalIncludingUnfoldedOperations.set(newRows.length);
    return extractAndPad(newRows, RowsDisplayedStart, DisplayedRowCount);
  }

  const RowsCurrentlyDisplayed = derived(
    [
      GanttChartType,
      RowsAllFiltered,
      RowsDisplayedStart,
      DisplayedRowCount,
      RowsUnfoldedForOrderGantt,
    ],
    ([$GanttChartType, $RowsAllFiltered, $RowsDisplayedStart, $DisplayedRowCount, $RowsUnfoldedForOrderGantt]) => {
      if ($GanttChartType === GanttChartTypes.OrderGantt) {
        return extraAndPadWithOperationUnfolded($RowsAllFiltered, $RowsDisplayedStart, $DisplayedRowCount, $RowsUnfoldedForOrderGantt);
      }
      return extractAndPad($RowsAllFiltered, $RowsDisplayedStart, $DisplayedRowCount)
    }
  )

  // subscriptions for configuration storage
  const GetConfigurationFunc = derived(GanttChartType, $GanttChartType => {
    switch ($GanttChartType) {
      case GanttChartTypes.ResGantt:
      case GanttChartTypes.SimpleResGantt:
      case GanttChartTypes.CustomizedResGantt:
        return () => getResGanttConfiguration(get(UserId), get(ProjectId))
      default:
        return () => getOrderGanttConfiguration(get(UserId), get(ProjectId))
    }
  })
  const SaveConfigurationFunc = derived(GanttChartType, ($GanttChartType) => {
    switch ($GanttChartType) {
      case GanttChartTypes.ResGantt:
      case GanttChartTypes.SimpleResGantt:
      case GanttChartTypes.CustomizedResGantt:
        return (config) => saveResGanttConfiguration(config, get(UserId), get(ProjectId))
      default:
        return (config) => saveOrderGanttConfiguration(config, get(UserId), get(ProjectId))
    }
  })
  RowsDisplayedStart.subscribe(val => {
    get(SaveConfigurationFunc)({rowsDisplayedStart: val})
  })
  RowsUnfoldedForOrderGantt.subscribe(val => {
    get(SaveConfigurationFunc)({unfoldedOperations: Array.from(val)})
  })
  RowSearchPattern.subscribe(val => {
    get(SaveConfigurationFunc)({rowSearchPattern: val})
  })
  CurrentStyle.subscribe(val => {
    if (Object.keys(val).length) {
      get(SaveConfigurationFunc)({styleObject: val})
    }
  })
  DisplayedRowCount.subscribe(val => {
    get(SaveConfigurationFunc)({rowCount: val})
  })
  PreventMistakes.subscribe(val => {
    get(SaveConfigurationFunc)({preventMistakes: val})
  })

  return {
    GanttChartType,
    GanttChartTypes,
    BasisTime,
    StartDate,
    EndDate,
    DisplayedStartDate,
    DisplayedEndDate,
    CurrentDisplayedStartDate,
    CurrentDisplayedEndDate,
    ValidStartDate,
    ValidEndDate,
    TransactionsUrl,
    RowName,
    ProjectName,
    ProjectId,
    UserId,
    Comment,
    StyleObjects,
    CurrentStyle,
    CurrentStyleInstance,
    StylesDict,
    RowsAllOwnedByStyle,
    RowsAllFiltered,
    RowsCountTotal,
    RowsCountTotalIncludingUnfoldedOperations,
    RowsDisplayedStart,
    RowsCurrentlyDisplayed,
    RowsCached,
    RowsUnfoldedForOrderGantt,
    ElementLocations,
    InternalLowerSplits,
    ShiftsList,
    Tasks,
    TasksUI,
    ReadyToRerender,
    SelectedObject,
    SelectedId,
    RowDownloadUrl,
    Transactions,
    TransactionRegisteringFunction,
    LastUploadTimestamp,
    LastTransactionDownloadTimestamp,
    TimelineItems,
    TimelineHighlight,
    DisplayedRowCount,
    MaxRowsWhereTextIsRendered,
    StartDayDisplayTime,
    EndDayDisplayTime,
    StartDayDisplayTimePercent,
    EndDayDisplayTimePercent,
    RowSearchPattern,
    DisplayedSpanInSeconds,
    PreventMistakes,
    OPTIMIZATION_HideBarStringRowThreshold,
    OPTIMIZATION_HideBarBorderRowThreshold,
    OPTIMIZATION_HideShiftsRowThreshold,
    OPTIMIZATION_BarSimplifyShapeRowThreshold,
    ExtraColumns,
    LeftTableWidth,
    GetConfigurationFunc,
    SaveConfigurationFunc,
  }
}

/**
 * Updates the given ganttStore with the updates and returns the updated ganttStore
 * @param {GanttStore} ganttStore
 * @param {Partial<GanttStore>} updates
 * @returns {GanttStore}
 */
export function batchUpdateGanttStore(ganttStore, updates) {
  const properties = Object.keys(GANTT_STORE_PROPERTIES)
  for (const propertyName of properties) {
    const updatedValue = updates[propertyName]
    if (updatedValue) ganttStore[propertyName].set(updatedValue)
  }
  return ganttStore
}

export function printGanttStore(ganttStore) {
  const properties = Object.keys(GANTT_STORE_PROPERTIES)
  const obj = {}
  for (const propertyName of properties) {
    if (propertyName === "GanttChartTypes") continue
    obj[propertyName] = get(ganttStore[propertyName])
  }
  console.log(obj)
}
