import GanttHelper from "./GanttHelper.js";
import SettingsTypes from "../settings/SettingsTypes.js";
import { derived, get } from "svelte/store";
import { stringifyAdvancedFilterObj } from "../../utils/filter.js";

const _SIDE_sections = {
  1: {translationKey: "frontend:ganttChart.settings.sections.style.title"},
  2: {translationKey: "frontend:ganttChart.settings.sections.displaySettings.title"},
  3: {translationKey: "generic.filterShort"},
  //4: {translationKey: "frontend:ganttChart.settings.sections.specific.resGantt.title"},
};

// Main key: SIDE Section number
const _SIDE_categories = {
  1: [10],
  2: [200,201,202,203],
  3: [300,399],
  4: [400]
};

const _CENTER_groups = {

};

// Inputs or categories
function __get_entries(GanttStore){
  return derived(
    [
      GanttStore.RowName,
      GanttStore.StyleObjects,
      GanttStore.CurrentStyle,
      GanttStore.DisplayedRowCount,
      GanttStore.RowSearchPattern,
      GanttStore.StartDayDisplayTime,
      GanttStore.EndDayDisplayTime,
      GanttStore.PreventMistakes,
    ],
    ([
      $RowName,
      $StyleObjects,
      $CurrentStyle,
      $DisplayedRowCount,
      $RowSearchPattern,
      $StartDayDisplayTime,
      $EndDayDisplayTime,
      $PreventMistakes,
    ]) => {
      return {
        10: {
          Type: SettingsTypes.ESettingsEntryType.INPUT,
          InputType: SettingsTypes.ESettingsInputType.SELECT,
          SelectOptions: $StyleObjects.map((style)=>({text: style.StyleName, value: style.ObjectID})),
          OnChangeFunction: (e) => {
            let newStyleObject = $StyleObjects.find((o) => o.ObjectID == e.target.value);
            if (!newStyleObject) {
              console.error(`Style Object ${currentStyleObjectID} not found`);
            } else {
              GanttStore.CurrentStyle.set(newStyleObject);
            }
          },
          DefaultValue: $CurrentStyle.ObjectID
        },
        11: {
          Label: "前後工程　時間軸表示",
          Type: SettingsTypes.ESettingsEntryType.INPUT,
          InputType: SettingsTypes.ESettingsInputType.SELECT,
          SelectOptions: [{text: "Default", value: "Default"}, {text: "Test", value: "Test"}],
          OnChangeFunction: () => {},
          DefaultValue: "Default"
        },
        200: {
          Label: "表示期間",
          LabelTranslationKey: "frontend:ganttChart.settings.displaySpan.label",
          Type: SettingsTypes.ESettingsEntryType.INPUT,
          InputType: SettingsTypes.ESettingsInputType.SELECT,
          SelectOptions: [
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.1day", value: "1Day"},
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.3days", value: "3Day"},
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.1week", value: "1Week"},
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.2weeks", value: "2Week"},
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.1month", value: "1Month"},
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.2months", value: "2Month"},
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.3months", value: "3Month"},
            {translationKey: "frontend:ganttChart.settings.displaySpan.choices.1year", value: "1Year"},
          ],
          OnChangeFunction: (e) => {
            const displ = GanttHelper._gantHelper_convertToSeconds(e.target.value);

            // update the settings in localStorage
            get(GanttStore.SaveConfigurationFunc)({displaySpanInSeconds: displ})

            GanttStore.DisplayedSpanInSeconds.set(displ);
            //new Date(this.currentDisplayedStartDate.getTime() + this.displayedSpanInSeconds * 1000)
          },
          // DefaultValue: "1Week"
          // TO-DO: Should choose correct option in accordance to configuration, e.g., displ = 604800 => choose 1 week
        },
        201: {
          Label: "表示時間帯（開始）",
          LabelTranslationKey: "frontend:ganttChart.settings.startDayDisplayTime.label",
          Type: SettingsTypes.ESettingsEntryType.INPUT,
          InputType: SettingsTypes.ESettingsInputType.TIME,
          OnInputFunction: (e) => {
            // update the settings in localStorage
            get(GanttStore.SaveConfigurationFunc)({startDayDisplayTime: e.target.value})

            GanttStore.StartDayDisplayTime.set(e.target.value)
          },
          DefaultValue: $StartDayDisplayTime
        },
        202: {
          Label: "表示時間帯（終了）",
          LabelTranslationKey: "frontend:ganttChart.settings.endDayDisplayTime.label",
          Type: SettingsTypes.ESettingsEntryType.INPUT,
          InputType: SettingsTypes.ESettingsInputType.TIME,
          OnInputFunction: (e) => {
            // update the settings in localStorage
            get(GanttStore.SaveConfigurationFunc)({endDayDisplayTime: e.target.value})

            GanttStore.EndDayDisplayTime.set(e.target.value)
          },
          DefaultValue: $EndDayDisplayTime
        },
        203: {
          Label: $RowName + "表示数",
          LabelTranslationKey: "frontend:ganttChart.settings.displayedRowCount.label",
          Type: SettingsTypes.ESettingsEntryType.INPUT,
          InputType: SettingsTypes.ESettingsInputType.NUMBER,
          MinValue: 1,
          OnInputFunction: (e) => {
            // update the settings in localStorage
            get(GanttStore.SaveConfigurationFunc)({rowCount: parseInt(e.target.value, 10)})

            GanttStore.DisplayedRowCount.set(e.target.value)
          },
          DefaultValue: $DisplayedRowCount
        },
        400: {
          LabelTranslationKey: "frontend:ganttChart.settings.preventMistakes.label",
          Type: SettingsTypes.ESettingsEntryType.TOGGLE,
          OnChange: (e) => {
            // update the settings in localStorage
            get(GanttStore.SaveConfigurationFunc)({preventMistakes: e.target.checked})

            GanttStore.PreventMistakes.update((v) => e.target.checked);
          },
          DefaultValue: $PreventMistakes,
        },
        300: {
          Type: SettingsTypes.ESettingsEntryType.INPUT,
          InputType: SettingsTypes.ESettingsInputType.FILTER,
          Choices: () => get(GanttStore.RowsAllOwnedByStyle)?.map(row => row.Alias) ?? [],
          OnQueryDeterminedFunction: (query) => GanttStore.RowSearchPattern.set(query),
          OnAdvancedFilterDeterminedFunction: filterObj => GanttStore.RowSearchPattern.set(stringifyAdvancedFilterObj(filterObj)),
          OnClearFilterFunction: () => GanttStore.RowSearchPattern.set(""),
          DefaultValue: $RowSearchPattern
        },
        399: {
          Type: SettingsTypes.ESettingsEntryType.BUTTON,
          ButtonTextTranslationKey: "frontend:ganttChart.settings.clearFilter.label",
          OnClick: () => GanttStore.RowSearchPattern.set(""),
        },
        20: {
          Icon: "",
          Label: "資源ガントチャート",
          Type: SettingsTypes.ESettingsEntryType.CATEGORY,
          Opens: 20,
        },
        21: {
          Icon: "",
          Label: "タイムライン",
          Type: SettingsTypes.ESettingsEntryType.CATEGORY,
          Opens: 21,
        },
        22: {
          Icon: "",
          Label: "前後工程　マップ表示",
          Type: SettingsTypes.ESettingsEntryType.CATEGORY,
          Opens: 22,
        },
        23: {
          Icon: "",
          Label: "前後工程　時間軸表示",
          Type: SettingsTypes.ESettingsEntryType.CATEGORY,
          Opens: 23,
        },
      }
    }
  )
}

export function createSettingsEntries(GanttStore){
  return {
    Sections: _SIDE_sections,
    Categories: _SIDE_categories,
    Groups: _CENTER_groups,
    Entries: () => __get_entries(GanttStore),
  }
}
