<script>
  import { beforeUpdate } from 'svelte';
  import GanttHelper from './GanttHelper.js';
  import ResourceGanttBarUnit from './ResourceGanttBarUnit.svelte';
  import ResourceGanttBarFooter from './ResourceGanttBarFooter.svelte';
  import { SelectRectangle } from "../../utils/selectRectangle";
  import { roundToNearestTime } from '../../utils/time.js';

  export let GanttStore

  export let ID;
  export let Height;
  export let Resource;
  export let OriginY;
  export let Data;
  export let StartDate;
  export let EndDate;
  export let Style;
  export let AdjustStamp;
  export let ganttChartType;

  const {
    TimelineItems
  } = GanttStore;

  $: simplifiedProductionArr = null;


  $: simple = (ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt); // whether it is simple gantt chart bar;
  $: {
    if (simple) { // For simple resource gantt, only reserve the earliest and latest date
      const allDates = [...Data._productionArr, ...Data._teardownArr, ...Data._setupArr];

      const earliestDate = allDates.reduce((earliest, current) =>
          current < earliest ? current : earliest, allDates[0]);

      const latestDate = allDates.reduce((latest, current) =>
          current > latest ? current : latest, allDates[0]);

      simplifiedProductionArr = [roundToNearestTime(earliestDate, 4, true), roundToNearestTime(latestDate, 4)];
    }
  }

  $: Selected = $TimelineItems.some((el) => el[0] == ID);
  //SelectRectangle.IsSelectedId(ID.toString());
  let optimizationBarString = 20;
  let optimizationBarBorder = 30;
  let optimizationShapeSimplify = 50;
  let displayedRowCount = 5;

  $: DisplayTextOptimization = (displayedRowCount * (Data._necessarySpace / Data._takenResourceCount)) <= optimizationBarString;

  // 多重資源
  const TAJUU_Ratio = (Data._takenResourceCount / Data._necessarySpace);
  const TAJUU_Offset = (Data._verticalPosition / Data._necessarySpace);

  const CalculateStampX = (stamp) => Math.max(0, Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime())));
  const CalculateStampXDifferential = (stamp1, stamp2) => CalculateStampX(stamp2) - CalculateStampX(stamp1);

  const BASE_BLOCK_HEIGHT = 0.7;
  const BASE_BLOCK_OFF = 0.15;
  const SUB_BLOCK_HEIGHT = 0.4;
  const SUB_BLOCK_OFF = 0.15; // Added to BASE_BLOCK_OFF
  const RESULT_BAR_THICKNESS = 0.04;

  $: TextBoxWidth = (Data._productionArr.length > 0) ? `${CalculateStampXDifferential(Data._productionArr[0],Data._productionArr[Data._productionArr.length-1])}%"` : "100%";

  let hbsrtunsub = () => {};
  let bssrtunsub = () => {};
  let hbbrtunsub = () => {};
  let drcunsub = () => {};

  beforeUpdate(() => {
    hbsrtunsub();
    bssrtunsub();
    hbbrtunsub();
    drcunsub();

    //Selected = SelectRectangle.IsSelectedId(ID.toString());
    hbsrtunsub = GanttStore.OPTIMIZATION_HideBarStringRowThreshold.subscribe((hbsrt) => optimizationBarString = hbsrt);
    bssrtunsub = GanttStore.OPTIMIZATION_BarSimplifyShapeRowThreshold.subscribe((bssrt) => optimizationShapeSimplify = bssrt);
    bssrtunsub = GanttStore.OPTIMIZATION_HideBarBorderRowThreshold.subscribe((hbbrt) => optimizationBarBorder = hbbrt);
    hbbrtunsub = GanttStore.DisplayedRowCount.subscribe((drc) => displayedRowCount = drc);
  });

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<g
  class={"r_" + Resource + " selectable resourceganttbar" + (Selected ? " selected" : "") + (ganttChartType === GanttStore.GanttChartTypes.CustomizedResGantt ? " customized" : "")}
  id={ID}
  on:click={SelectRectangle.SingleClickEventWrapperPassive()}
>
  {#if Data._setupArr.length >= 2 && !simple}
    <ResourceGanttBarUnit
      Timestamps={Data._setupArr}
      Color="#999999"
      StartYMain={OriginY+Height*BASE_BLOCK_OFF+((Data._verticalPosition / Data._necessarySpace)*Height*BASE_BLOCK_HEIGHT)
        + Height*SUB_BLOCK_OFF*(Data._takenResourceCount / Data._necessarySpace)}
      HeightYMain={Height*SUB_BLOCK_HEIGHT*(Data._takenResourceCount / Data._necessarySpace)}
      StartYSub={OriginY+Height*0.55}
      HeightYSub={Height*0.2*(Data._takenResourceCount / Data._necessarySpace)}
      StartDate={StartDate}
      EndDate={EndDate}
      HiddenBarBorders={true}
      SimplifiedShape={displayedRowCount >= optimizationShapeSimplify}
      AdjustStamp={AdjustStamp}
    />
  {/if}

  {#if Data._teardownArr.length >= 2 && !simple}
    <ResourceGanttBarUnit
      Timestamps={Data._teardownArr}
      Color="#999999"
      StartYMain={OriginY+Height*BASE_BLOCK_OFF+((Data._verticalPosition / Data._necessarySpace)*Height*BASE_BLOCK_HEIGHT)
        + Height*SUB_BLOCK_OFF*(Data._takenResourceCount / Data._necessarySpace)}
      HeightYMain={Height*SUB_BLOCK_HEIGHT*(Data._takenResourceCount / Data._necessarySpace)}
      StartYSub={OriginY+Height*0.55}
      HeightYSub={Height*0.2*(Data._takenResourceCount / Data._necessarySpace)}
      StartDate={StartDate}
      EndDate={EndDate}
      HiddenBarBorders={true}
      SimplifiedShape={displayedRowCount >= optimizationShapeSimplify}
      AdjustStamp={AdjustStamp}
    />
  {/if}

  {#if Data._productionArr.length >= 2}
    {#if ganttChartType !== GanttStore.GanttChartTypes.CustomizedResGantt}
      <ResourceGanttBarUnit
        Timestamps={simple ? simplifiedProductionArr : Data._productionArr}
        Color={simple ? "#7dc4ff" : Data._color[Style]}
        StartYMain={OriginY+Height*BASE_BLOCK_OFF+((Data._verticalPosition / Data._necessarySpace)*Height*BASE_BLOCK_HEIGHT)}
        HeightYMain={Height*BASE_BLOCK_HEIGHT*(Data._takenResourceCount / Data._necessarySpace)}
        StartYSub={OriginY+Height*0.7}
        HeightYSub={Height*0.2*(Data._takenResourceCount / Data._necessarySpace)}
        StartDate={StartDate}
        EndDate={EndDate}
        HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
        SimplifiedShape={displayedRowCount >= optimizationShapeSimplify}
        AdjustStamp={AdjustStamp}
      />
    {/if}
    {#if  ganttChartType !== GanttStore.GanttChartTypes.CustomizedResGantt && (Data._resStatus === "B" || Data._resStatus === "T" && Data._resultQty > 0) && !simple}
      <ResourceGanttBarUnit
        Timestamps={Data._productionArr}
        Color="url(#stripe)"
        StartYMain={OriginY+Height*BASE_BLOCK_OFF+((Data._verticalPosition / Data._necessarySpace)*Height*BASE_BLOCK_HEIGHT)}
        HeightYMain={Height*BASE_BLOCK_HEIGHT*(Data._takenResourceCount / Data._necessarySpace)}
        StartYSub={OriginY+Height*0.7}
        HeightYSub={Height*0.2*(Data._takenResourceCount / Data._necessarySpace)}
        StartDate={StartDate}
        EndDate={EndDate}
        HiddenBarBorders={true}
        SimplifiedShape={displayedRowCount >= optimizationShapeSimplify}
        AdjustStamp={AdjustStamp}
        DisplayRatio={(Data._resStatus === "B") ? 1 : Math.min(1, parseFloat(Data._resultQty) / parseFloat(Data._outputItemQty))}
      />
    {/if}

    {#if ganttChartType === GanttStore.GanttChartTypes.CustomizedResGantt}
      {#each Data._productionArr as item, index (index) }
        {#if index % 2 === 0}
          <ResourceGanttBarUnit
            Timestamps={[Data._productionArr[index], Data._productionArr[index + 1]]}
            Color={Data._color[Style]}
            StartYMain={OriginY+Height*BASE_BLOCK_OFF+((Data._verticalPosition / Data._necessarySpace)*Height*BASE_BLOCK_HEIGHT)}
            HeightYMain={Height*BASE_BLOCK_HEIGHT*(Data._takenResourceCount / Data._necessarySpace)}
            StartYSub={OriginY+Height*0.7}
            HeightYSub={Height*0.2*(Data._takenResourceCount / Data._necessarySpace)}
            StartDate={StartDate}
            EndDate={EndDate}
            HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
            SimplifiedShape={displayedRowCount >= optimizationShapeSimplify}
            AdjustStamp={AdjustStamp}
            GanttChartType={ganttChartType}
            {GanttStore}
            IntervalQtyDisplayPropertyPack={{Height, OriginY,
              "trc": Data._takenResourceCount, "ns": Data._necessarySpace, 'vp': Data._verticalPosition,
              BASE_BLOCK_OFF,
              BASE_BLOCK_HEIGHT,
              RESULT_BAR_THICKNESS}}
            IntervalQty={Data.intervalQty?.[Math.floor(index / 2)][0]}
            AccIntervalQty={Data.intervalQty?.[Math.floor(index / 2)][1]}
          />
          {/if}
      {/each}
    {/if}

    {#if Data._resStatus !== "A" && !simple}
      <ResourceGanttBarFooter
        Timestamps={Data._productionArr}
        Color={GanttHelper.GetStatusColor(GanttHelper.GetStatus(Data._resStatus))}
        StartY={OriginY+Height*BASE_BLOCK_OFF+((Data._verticalPosition / Data._necessarySpace)*Height*BASE_BLOCK_HEIGHT)
          + Height*BASE_BLOCK_HEIGHT*(Data._takenResourceCount / Data._necessarySpace)
          - Height*RESULT_BAR_THICKNESS*(Data._takenResourceCount / Data._necessarySpace)}
        HeightY={Height*RESULT_BAR_THICKNESS*(Data._takenResourceCount / Data._necessarySpace)}
        StartDate={StartDate}
        EndDate={EndDate}
        HiddenBarBorders={displayedRowCount >= optimizationBarBorder}
        AdjustStamp={AdjustStamp}
      />
    {/if}
  {/if}

  <title>
    {Data._hoverTooltip[Style]}
  </title>
</g>
{#if DisplayTextOptimization && Data._productionArr.length >= 1 && !simple}
  <svg
  x="{CalculateStampX(Data._productionArr[0])}%"
  y="{OriginY+((Data._takenResourceCount / Data._necessarySpace) < 1 ? Height*BASE_BLOCK_OFF : Height*(BASE_BLOCK_OFF + 0.02))+((Data._verticalPosition / Data._necessarySpace)*Height*BASE_BLOCK_HEIGHT)}%"
  width="{CalculateStampXDifferential(Data._productionArr[0],Data._productionArr[Data._productionArr.length-1])}%"
  height="{Height*(BASE_BLOCK_HEIGHT - RESULT_BAR_THICKNESS)*(Data._takenResourceCount / Data._necessarySpace)}%"
  style="overflow:none;"
  >

    <text
      fill={(Data._late) ? "red" : "black"}
      font-size="16px"
      x="0"
      y="1px"
      text-anchor="start"
      dominant-baseline="hanging"
      pointer-events="none"
    >
    {#if ganttChartType !== GanttStore.GanttChartTypes.CustomizedResGantt}
      {#if Data._taskText[Style]}
        {#each Data._taskText[Style].split("\n") as text, idx}
          <tspan
            x="0"
            dy="{(idx==0) ? 0 : '16px'}"
          >
            {text}
          </tspan>
        {/each}
      {/if}
    {:else if ganttChartType === GanttStore.GanttChartTypes.CustomizedResGantt}
      {`${Data._orderItem}/${Data._outputItem}`}
    {/if}
    </text>


  </svg>
{/if}

<style>
  g.resourceganttbar {
    outline: solid 4px hsla(0, 0%, 0%, 0);
    outline-offset: -4px;
  }

  g.resourceganttbar.selected {
    outline-color: yellow!important;
  }

  g.resourceganttbar:not(.selected, .customized) {
    outline-color: hsla(0, 0%, 0%, 0)!important;
  }
  g.resourceganttbar:not(.selected) {
    outline: solid 1px hsla(0, 0%, 0%, 0);
    outline-color:black;
    outline-offset: 1px;
  }

</style>
