<script>
  import GanttHelper from './GanttHelper.js';

  export let Timestamps;
  export let Color;
  export let StartYMain;
  export let HeightYMain;
  export let StartDate;
  export let EndDate;
  export let AdjustStamp;
  export let Transparent = false;
  export let Highlight = false;

  // Optimization parameters
  export let HiddenBarBorders;
  export let SimplifiedShape;

  const CalculateStampX = (stamp) => Math.max(0, Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime())));
  const CalculateStampXDifferential = (stamp1, stamp2) => CalculateStampX(stamp2) - CalculateStampX(stamp1);
  const DarkenColor = (color) => GanttHelper._gantHelper_darkenColor(GanttHelper._gantHelper_rgbToHex(color), 30);

</script>

{#if SimplifiedShape === true}
  {#if !Highlight}
    <rect
      x="{CalculateStampX(Timestamps[0])}%"
      y="{StartYMain}%"
      height="{HeightYMain}%"
      width="{CalculateStampXDifferential(Timestamps[0],Timestamps[Timestamps.length-1])}%"
      fill={Color}
      fill-opacity={Transparent ? "0.5" : "1"}
      stroke={(HiddenBarBorders === true) ? "none" : "#000000"}
      stroke-width={(HiddenBarBorders === true) ? 0 : 1}
      stroke-opacity="40%"
      rx="0.2%"
      ry="0.2%"
    />
  {:else}
    <rect
        x="{CalculateStampX(Timestamps[0])}%"
        y="{StartYMain}%"
        height="{HeightYMain}%"
        width="{CalculateStampXDifferential(Timestamps[0],Timestamps[Timestamps.length-1])}%"
        fill={Color}
        fill-opacity={Transparent ? "0.5" : "1"}
        stroke={(HiddenBarBorders === true) ? "none" : "#ffff00"}
        stroke-width={(HiddenBarBorders === true) ? 0 : 10}
        stroke-opacity="40%"
        rx="0.2%"
        ry="0.2%"
      />
  {/if}
{/if}

<style>

</style>
